import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel, getCodeRemark } from '@core/utils/filter'
import { toDate } from '@core/utils/dayjs/utils'

export default function purchaserefundUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'refund_no', label: '退款订单编号', sortable: true },
    { key: 'order_no', label: '采购订单编号', sortable: true },
    { key: 'name', label: '名称', sortable: true },
    { key: 'supplier', label: '供应商' ,formatter:(value, key, item)=>(getCodeRemark("company",item.ext.supplier) || getCodeLabel("company",item.ext.supplier))},
    { key: 'company', label: '签约主体',formatter:(value,key,item)=>(getCodeRemark("company",item.ext.company) || getCodeLabel("company",item.ext.company))},
    { key: 'name', label: '名称', sortable: true },
    { key: 'qty', label: '退款数量', sortable: true },
    { key: 'product_cost_tax', label: '含税单价', sortable: true },
    { key: 'subtotal', label: '总退款金额', sortable: true },
    { key: 'pay_object', label: '支付对象'},
    { key: 'refund_time', label: '退款日期', sortable: true ,formatter:(value)=>toDate(value)},
    { key: 'creator', label: '提交人', sortable: true,formatter:(value)=>getCodeLabel("user",value)},
    { key: 'remark', label: '备注', sortable: true },
    { key: 'attachments', label: '附件', sortable: true },
    { key: 'status', label: '状态', sortable: true },
   /* { key: 'create_time', label: '添加时间', sortable: true },

    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人ID', sortable: true },*/
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('create_time')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = store.getters['purchaserefund/getCondition']
    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
      orderId:config.orderId,
    }
    Object.assign(params,condition)
    store
      .dispatch('purchaserefund/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
