<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-purchaseorder-list'}"
                  v-if="orderId"
              >
                <span class="text-nowrap">返回订单列表</span>
              </b-button>
            </div>

          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <search @refreshTable=refetchData />
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >


        <template #cell(refund_no)="data">
          [#{{ data.item.id }}] {{data.value}}
        </template>

        <template #cell(order_no)="data">
          <b-link
              :to="{name:'apps-purchaseorder-list',query:{order_id:data.item.order_id}}"
              target="_blank"
          >
            {{ data.value }}
          </b-link>
        </template>

        <!-- 备注 -->
        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{  data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_refund_status',data.value)}`"
          >
            {{ getCodeLabel('purchase_refund_status', data.value) }}
          </b-badge>
        </template>

        <template  #cell(attachments)="data">
          <attachment-upload theme="preview-dropdown"
                             attachment_id="attachments"
                             :id="data.value"
                             :readonly="true"
          />
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="view(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>
            <div v-if="data.item.status === 1">
              <b-dropdown-item @click="edit(data.item)" >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="approve(data.item.refund_id,2)" >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">提交审核</span>
              </b-dropdown-item>
            </div>

            <div v-if="auth && data.item.status === 2">
              <b-dropdown-item @click="approve(data.item.refund_id,3)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">通过</span>
              </b-dropdown-item>

              <b-dropdown-item @click="approve(data.item.refund_id,1)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
          id="modal-refund"
          centered
          size="lg"
          hide-footer
          title="退款申请"
          ref="refundModal"
      >
        <purchase-refund-edit :refund-id="curRow.refund_id" :readonly="readonly" @closeModal="closeModalRefund" @refresh="refetchData"
        ></purchase-refund-edit>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs, computed } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import purchaserefundUseList from './purchaserefundUseList'
import purchaserefundStore from './purchaserefundStore'
import PurchaseRefundEdit from '@/views/apps/purchaserefund/PurchaseRefundEdit'
import { useRouter } from '@core/utils/utils'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import Search from '@/views/apps/purchaserefund/search'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import axios from "@/libs/axios";

export default {
  components: {
    AttachmentUpload,
    Search,
    PurchaseRefundEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const {
      router,
      route
    } = useRouter()
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaserefund')) store.registerModule('purchaserefund', purchaserefundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaserefund')) store.unregisterModule('purchaserefund')
    })

    const state = reactive({
      curRow: {},
      readonly: false,
      refundModal: undefined,

      orderId:undefined
    })

    const methods = {
      relationTo(data){
        const orderType = data.order_type
        let res = ""
        switch (orderType){
          case 1:
            res = { name: 'apps-purchaseinbound-warehouseList', query: { inbound_id: data.relational_id } }
            break;
          case 2:
            res = {name:'apps-allotoutbound-warehouse',query:{outbound_id:data.relational_id}}
            break;
        }
        return res
      },
      edit(row) {
        state.readonly = false
        state.curRow = row
        state.refundModal.show()
      },
      view(row) {
        state.readonly = true
        state.curRow = row
        state.refundModal.show()
      },
      closeModalRefund() {
        state.refundModal.hide()
      },
      approve(id, status) {
        store.dispatch('purchaserefund/approve', {
          id: id,
          status: status
        })
            .then(res => {
              if (res.data.code === 0) {
                if (status === 1){
                  toast.success("驳回成功")
                }else if(status === 3){
                  toast.success("审批成功")
                }
                useList.refetchData()
              } else {
                if (status === 1){
                  toast.error("驳回失败")
                }else if(status === 3){
                  toast.error("审批失败")
                }
              }
            })
      },
    }

    const user = getUserData()
    const auth = computed(() => user.role_id === 11 || user.role_id === 12 || user.role_id === 14)
    state.orderId= route.value.query.order_id
    const config = {}
    if (state.orderId) {
      config.orderId = state.orderId
    }
    const useList = purchaserefundUseList(config)

    return {
      ...toRefs(state),
      ...methods,
      ...useList,
      auth,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
