<template>
  <b-card style="width:100%">
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="退款订单编号"
              label-for="orderNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.refundNo"
                name="refundNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="采购订单号"
              label-for="orderNo"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.orderNo"
                name="orderNo"
                class="d-inline-block mr-1"
                size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品名称"
              label-for="name"
              label-size="sm"
          >
            <b-form-input
                v-model="condition.name"
                name="name"
                class="d-inline-block mr-1"
                size="sm"
                placeholder="请输入名称"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="状态"
              label-for="orderStatus"
              label-size="sm"
          >
            <v-select
                id="status"
                :options="getCodeOptions('purchase_refund_status')"
                v-model="condition.status"
                name="orderStatus"
                class="select-size-sm"
                placeholder="请选择状态"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="供应商:"
              label-for="supplier_id"
              label-size="sm"
          >
            <b-form-input
                id="supplier_id"
                size="sm"
                v-model="condition.supplierName"
                name="supplierId"
                @click="showSingleModal('供应商')"
                readonly
                placeholder="点击搜索供应商"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="签约主体:"
              label-for="company_id"
              label-size="sm"
          >
            <b-form-input
                id="company_id"
                size="sm"
                v-model="condition.companyName"
                name="companyId"
                @click="showSingleModal('签约主体')"
                readonly
                placeholder="点击搜索签约主体"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="提交人:"
              label-for="creatorName"
              label-size="sm"
          >
            <b-form-input
                id="creatorName"
                size="sm"
                v-model="condition.creatorName"
                name="creatorName"
                @click="showSingleModal('提交人')"
                readonly
                placeholder="点击搜索提交人"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="退款日段:"
              label-size="sm"
          >
            <flat-pickr
                v-model="condition.time"
                class="form-control"
                :config="{
                  mode: 'range'
                }"
            />
          </b-form-group>
        </b-col>

        <b-col
                cols="12"
                md="3"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="支付对象"
                  label-for="payObject"
                  label-size="sm"
          >
            <b-form-input
                    v-model="condition.pay_object"
                    name="payObject"
                    class="d-inline-block mr-1"
                    size="sm"
                    placeholder="请输入支付对象"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="6"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
              variant="outline-primary"
              type="reset"
              class="mr-1"
          >
            <span class="align-right">重置</span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="Export"
          >
            <span class="align-right">导出</span>
          </b-button>

        </b-col>
        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
          />

          <user-select
              ref="creatorSelect" :department-id="1" v-else-if="'提交人' === modalName "
          />

        </b-modal>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import purchaserefundStore from '@/views/apps/purchaserefund/purchaserefundStore'
import CompanySelect from '@/views/apps/company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import axios from "@/libs/axios";
import Ripple from "vue-ripple-directive";

export default {
  components: { XyDatePicker, UserSelect, CompanySelect },
  props: {},
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaserefund')) store.registerModule('purchaserefund', purchaserefundStore)

    onMounted(() => {
      state.condition = store.getters['purchaserefund/getCondition']
    })

    const state = reactive({
      condition: {},
      companyType: '',
      modalName: '',
      singleModal:undefined,
    })
    const methods = {
      showSingleModal(modalName) {
        if (modalName === '签约主体') {
          state.companyType = 'Group'
        } else if (modalName === '供应商') {
          state.companyType = 'Supplier'
        }

        state.modalName = modalName
        state.singleModal.show()
      },
      onSelectSingle() {
        let data = {}
        switch (state.modalName) {
          case '签约主体': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.company_id = data[0].company_id
              state.condition.companyName = data[0].company_name
            }
            break
          }
          case '供应商': {
            data = this.$refs.companySelect.getSelected()
            if (data.length > 0) {
              state.condition.supplier_id = data[0].company_id
              state.condition.supplierName = data[0].company_name
            }
            break
          }
          case '提交人': {
            data = this.$refs.creatorSelect.getSelected()
            if (data.length > 0) {
              state.condition.creator = data[0].user_id
              state.condition.creatorName = data[0].full_name
            }
            break
          }
        }
      },
      refreshTable() {
        let time = state.condition.time
        if (time && !time.includes('至')) {
          toast.error("请选择范围日期")
          return;
        }
        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {
          companyName: undefined,
          supplierName: undefined,
          creatorName: undefined,
        }
        store.commit('purchaserefund/updateCondition', state.condition)
        methods.refreshTable()
      },
    }

    const Export = function () {
      const condition = state.condition


      axios({
            url: '/api/exportexcelfile/createPurchaseRefundExcel',
            method:'get',
            params: {
              companyId: condition.company_id,
              creator: condition.creator,
              name: condition.name,
              orderNo: condition.orderNo,
              refundNo: condition.refundNo,
              status: condition.status,
              supplierId: condition.supplier_id,
              time: condition.time
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "采购退款-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    return {
      ...toRefs(state),
      ...methods,
      //filter
      getCodeOptions,
      Export,
    }
  },
}
</script>

<style scoped>

</style>
